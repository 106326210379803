<div>
  <button *ngIf="!showCommentForm" class="add-comment-btn" type="button" mat-stroked-button (click)="leaveComment()">
    Leave a comment
  </button>
  <form *ngIf="showCommentForm" class="comment-form" [formGroup]="addCommentForm" (ngSubmit)="onSubmitComment()">
    <div class="comment-form-content">
      <mat-form-field class="comment-box" appearance="outline">
        <textarea
          matInput
          [rows]="3"
          formControlName="comment"
          placeholder="Leave a comment"
          (startTextInput)="startComment.emit()"
        >
        </textarea>
        <button
          *ngIf="currentUser$ | async as user"
          class="current-user-avatar-btn"
          mat-button
          matPrefix
          type="button"
          [matMenuTriggerFor]="userMenu"
        >
          <div>
            <ngx-avatar
              [name]="user?.data?.loggedin_fullname ?? 'Anonymous'"
              bgColor="#F9FAFB"
              fgColor="#475467"
              size="32"
            ></ngx-avatar>
            <mat-icon>arrow_drop_down</mat-icon>
          </div>
        </button>
        <mat-menu #userMenu="matMenu">
          <button class="user-menu-item" type="button" mat-menu-item (click)="logout()">
            <mat-icon>people</mat-icon>
            <span>Comment as Guest (Logout)</span>
          </button>
        </mat-menu>
        <mat-error *ngIf="addCommentForm.controls.comment.hasError('maxlength')" class="error-message">
          Comment cannot be more than
          {{ addCommentForm.controls.comment.getError('maxlength')['requiredLength'] }} characters long
        </mat-error>
      </mat-form-field>
    </div>

    <div class="comment-form-actions">
      <button type="button" mat-stroked-button (click)="cancel()">Cancel</button>
      <button
        type="submit"
        [disabled]="addCommentForm.invalid || !addCommentForm.controls.comment.value.length"
        mat-flat-button
        color="primary"
      >
        Send comment
      </button>
    </div>
  </form>
</div>
