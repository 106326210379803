import { Directive, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
    selector: '[startTextInput]',
    standalone: true,
})
export class StartTextInputDirective {
    @Output() startTextInput = new EventEmitter<void>();

    private _started: boolean = false;

    @HostListener('input')
    onInput() {
        if(!this._started) {
            this._started = true;
            this.startTextInput.emit();
        }
    }
}