import { ErrorHandler, Injectable, inject } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable, concatMap, filter, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs';
import { PublicCommentsService } from '../services/public-comments.service';
import { Comment, CreatePublicCommentDTO } from '@openreel/frontend/common/hosting/hosting-interfaces';

export interface PublicCommentsState {
  isLoading: boolean;
  videoToken: string | null;
  page: number;
  comments: Comment[];
  total: number;
  availableTokens: Record<number, string>;
}

@Injectable()
export class PublicCommentsManagerService extends ComponentStore<PublicCommentsState> {
  private publicCommentsService = inject(PublicCommentsService);
  private errorHandler = inject(ErrorHandler);

  constructor() {
    super({
      isLoading: false,
      videoToken: null,
      page: 0,
      total: -1,
      comments: [],
      availableTokens: {},
    });
  }

  isLoading$ = this.select((state) => state.isLoading);
  videoToken$ = this.select((state) => state.videoToken);
  page$ = this.select((state) => state.page);
  availableTokens$ = this.select((state) => state.availableTokens);
  comments$ = this.select((state) => state.comments);
  total$ = this.select((state) => state.total);
  isAllCommentsLoaded$ = this.select(this.comments$, this.total$, (comments, total) => comments.length >= total);

  public setVideoToken(token: string): void {
    this._setVideoToken(token);
    this._fetchComments();
  }

  public increasePage(): void {
    this._increasePage(1);
    this._fetchComments();
  }

  public fetchComments(): void {
    this._fetchComments();
  }

  public addPublicComment(dto: CreatePublicCommentDTO): void {
    this._addPublicComment(dto);
  }

  public deletePublicComment(comment: Comment): void {
    this._deletePublicComment(comment);
  }

  public updatePublicComment(comment: Comment): void {
    this._updatePublicComment(comment);
  }

  public refreshComments(): void {
    this.resetComments();
    this._fetchComments();
  }

  private resetComments = this.updater((state) => ({ ...state, comments: [], total: -1, page: 0 }));

  private _setIsLoading = this.updater((state, isLoading: boolean) => ({ ...state, isLoading }));

  private _setVideoToken = this.updater((state, token: string) => ({
    ...state,
    videoToken: token,
    page: 0,
    total: -1,
    comments: [],
  }));

  private _increasePage = this.updater((state, increment: number) => ({ ...state, page: state.page + increment }));

  private _addComments = this.updater((state, comments: Comment[]) => {
    const commentsWithPermissions = comments.map(comment => !comment ? comment : ({
      ...comment,
      permissions: {
        update: comment.permissions?.update || !!state.availableTokens[comment.id],
        remove: comment.permissions?.remove || !!state.availableTokens[comment.id],
        resolve: false,
      }
    }))
    return {
      ...state,
      comments: [...state.comments, ...commentsWithPermissions],
    }
  });

  private _addComment = this.updater((state, comment: Comment) => {
    const commentWithPermissions = {
      ...comment,
      permissions: {
        update: comment.permissions?.update || !!state.availableTokens[comment.id],
        remove: comment.permissions?.remove || !!state.availableTokens[comment.id],
        resolve: false,
      }
    }

    return {
      ...state,
      comments: [commentWithPermissions, ...state.comments],
    }
  });

  private _removeComment = this.updater((state, commentId: number) => {
    const _comments = [...state.comments];
    _comments[_comments.findIndex(item => item?.id === commentId)] = null
    const _availableTokens = { ...state.availableTokens };
    _availableTokens[commentId] = null;
    return { ...state, comments: _comments, availableTokens: _availableTokens };
  });

  private _updateComment = this.updater((state, payload: { commentId: number; comment: Comment }) => {
    const comments = [...state.comments];
    const index = comments.findIndex((item) => item?.id === payload.commentId);
    comments[index] = payload.comment;
    return { ...state, comments };
  });

  private _setTotal = this.updater((state, total: number) => ({ ...state, total }));

  private _increaseTotal = this.updater((state, increment: number) => ({ ...state, total: state.total + increment }));

  private _addCommentToken = this.updater((state, payload: { commentId: number; token: string }) => ({
    ...state,
    availableTokens: { ...state.availableTokens, [payload.commentId]: payload.token },
  }));

  private _fetchComments = this.effect((_: Observable<void>) =>
    _.pipe(
      withLatestFrom(this.videoToken$, this.page$),
      filter(([_, token]) => !!token),
      tap(() => this._setIsLoading(true)),
      concatMap(([_, videoToken, page]) =>
        this.publicCommentsService.fetchPublicComments(videoToken, { page }).pipe(
          tapResponse(
            (response) => {
              this._addComments(response.rows);
              this._setTotal(response.count);
              this._setIsLoading(false);
            },
            (error) => {
              this._handlerError(error);
              this._setIsLoading(false);
            }
          ),
        )
      )
    )
  );

  private _addPublicComment = this.effect((dto$: Observable<CreatePublicCommentDTO>) =>
    dto$.pipe(
      withLatestFrom(this.videoToken$),
      switchMap(([dto, token]) =>
        this.publicCommentsService.createPublicComment(token, dto).pipe(
          tapResponse(
            (response) => {
              this._addCommentToken({ commentId: response.comment.id, token: response.token });
              this._addComment(response.comment);
              this._increaseTotal(1);
            },
            (error) => this._handlerError(error)
          )
        )
      )
    )
  );

  private _updatePublicComment = this.effect((comment$: Observable<Comment>) =>
    comment$.pipe(
      withLatestFrom(this.availableTokens$),
      mergeMap(([comment, commentTokens]) =>
        this.publicCommentsService
          .updatePublicComment(comment.id, { comment: comment.comment }, commentTokens[comment.id])
          .pipe(
            tapResponse(
              (response) => {
                this._updateComment({ commentId: comment.id, comment: response });
              },
              (error) => this._handlerError(error)
            )
          )
      )
    )
  );

  private _deletePublicComment = this.effect((comment$: Observable<Comment>) =>
    comment$.pipe(
      withLatestFrom(this.availableTokens$),
      mergeMap(([comment, commentTokens]) =>
        this.publicCommentsService.deletePublicComment(comment.id, commentTokens[comment.id]).pipe(
          tapResponse(
            (response) => {
              this._removeComment(comment.id);
            },
            (error) => this._handlerError(error)
          )
        )
      )
    )
  );

  private _handlerError(error: unknown): void {
    try {
      this.errorHandler.handleError(error);
    } catch (e) {}
  }
}
