import { AfterViewInit, Component, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { HostingPlayerActions, HostingPlayerVideoEvent } from '@openreel/frontend/common/hosting/hosting-interfaces';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'openreel-embedded-player',
  templateUrl: './embedded-player.component.html',
  styleUrls: ['./embedded-player.component.scss'],
})
export class EmbeddedPlayerComponent implements AfterViewInit, OnDestroy {
  srcData$: Observable<{
    srcUrl: string;
    thumbUrl: string;
    title: string;
    autoplay: boolean;
    expired: boolean;
    accountId: string;
    idHash: string;
    slug: string;
    mute:boolean;
  }>;

  playerActions: HostingPlayerActions;

  isBrowser: boolean;

  constructor(private route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: string) {
    this.srcData$ = combineLatest([this.route.data, this.route.queryParams]).pipe(
      map(([data, q]) => ({
        ...data.srcData,
        thumbUrl: data.srcData.thumbnailSignedUrl[0],
        autoplay: +q.autoplay === 1,
        mute: +q.mute === 1,
      }))
    );
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      window?.addEventListener('message', this.onWindowMessage.bind(this), false);
    }
  }

  ngOnDestroy(): void {
    if (this.isBrowser) {
      window?.removeEventListener('message', this.onWindowMessage.bind(this));
    }
  }

  onPlayerReady(playerActions: HostingPlayerActions) {
    this.playerActions = playerActions;
  }

  onVideoEvent(event: HostingPlayerVideoEvent) {
    if (event.action === 'ended') {
      window.parent.postMessage({ ended: true }, '*');
    }
  }

  private onWindowMessage(event: MessageEvent) {
    const isAllowedOrigin = /openreel\.(com|cloud)(:3002)?$/.test(event.origin);

    if (!isAllowedOrigin) {
      console.error(`Received a message from unknown origin`, event);
      return;
    }

    if (event.data.play) {
      this.playerActions.play();
    } else if (event.data.pause) {
      this.playerActions.pause();
    } else if (event.data.currentTime) {
      this.playerActions.currentTime(event.data.currentTime);
    }
  }
}
