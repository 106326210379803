import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

export enum ErrorTypes {
  NotFound = 'not-found',
  Unauthorized = 'unauthorized',
  SomethingWrong = 'something-wrong',
  BadRequest = 'bad-request',
}

@Component({
  selector: 'openreel-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  error$ = this.route.data.pipe(map((e) => e.errorType));
  errorTypes = ErrorTypes;
  message: string;
  constructor(private route: ActivatedRoute) {}
  ngOnInit() {
    this.route.queryParams.pipe().subscribe((queryParams) => {
      this.message = queryParams['message'];
    });
  }
}
