<a (click)="toDetail(cardData.idHash, cardData.slug)">
  <mat-card class="public-mini-player">
    <div mat-card-image>
      <or-preview-thumb
        class="preview-thumb"
        (playButtonClick)="toDetail(cardData.idHash, cardData.slug)"
        [thumbUrl]="cardData.thumbnailSignedUrl[0]"
        [previewUrl]="cardData.thumbnailSignedUrl[1]"
        [alt]="cardData.altText"
      ></or-preview-thumb>
    </div>
    <mat-card-content>
      <div fxLayout fxLayoutAlign="start center" fxFill>
        <div fxFlex="1 1" class="list-title">
          <div
            class="video-title"
            [matTooltip]="cardData.title"
            [matTooltipPosition]="'above'"
            [matTooltipShowDelay]="500"
          >
            <span *ngIf="brandkitLoaded$ | async">{{ cardData.title }}</span>
          </div>
          <div class="created-duration">
            <span *ngIf="cardData.createdAt">{{ cardData.createdAt | date: 'MMM d, y' }}</span>
            <span *ngIf="cardData.duration">{{ cardData.duration * 1000 | date: 'm:ss' }}</span>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</a>
