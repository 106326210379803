// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { commonenv } from '@openreel/frontend/common/env/environment.develop';

export const environment = {
  ...commonenv,
  environmentName: 'Development',
  sentryTracesSampleRate: 1.0, // need to reduce later on
  sentryDSN: 'https://c0471846f1ad4ae2b8469eda04c89e0e@o215724.ingest.sentry.io/6248985',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
