<or-comment-list
  [commentTemplate]="commentTemplate"
  [comments]="comments$ | async"
  [isLoadingComments]="commentsManager.isLoading$| async"
  [isAllCommentsLoaded]="commentsManager.isAllCommentsLoaded$| async"
  (updateComment)="commentsManager.updatePublicComment($event)"
  (deleteComment)="onClickDeleteComment($event)"
  (clickUpdateComment)="onClickUpdateComment($event)"
  (loadMoreComments)="commentsManager.increasePage()"
></or-comment-list>
