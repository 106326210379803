<or-widget-layout [widgetName]="WidgetName.ContactUs" [isEnableConfigureMode]="isEnableConfigureMode">
    <button 
        *ngIf="isEnableConfigureMode" 
        widgetActions 
        class="settings-btn" 
        (click)="onClickSettings()"
        [class.invalid]="!widgetData?.sendToMail?.values?.length"
    >
        <mat-icon>settings</mat-icon>
    </button>
    <ng-container widgetContent>
        <form *ngIf="!(isMessageSent$ | async)" class="contactus-form" [formGroup]="contactusForm" (ngSubmit)="submitForm()">
            <div class="form-controls-container">
                <label class="form-label">Full name</label>
                <mat-form-field class="form-control" appearance="outline">
                    <input matInput formControlName="name" placeholder="Enter here">
                    <mat-error *ngIf="contactusForm.controls.name.hasError('required')" class="error-message">
                        Full name is required.
                    </mat-error>
                    <mat-error *ngIf="contactusForm.controls.name.hasError('maxlength')" class="error-message">
                        Full name cannot be more than {{ contactusForm.controls.name.getError('maxlength')['requiredLength'] }} characters long
                    </mat-error>
                </mat-form-field>
    
                <label class="form-label">Email address</label>
                <mat-form-field class="form-control" appearance="outline">
                    <input matInput formControlName="email" placeholder="Enter here">
                    <mat-error *ngIf="contactusForm.controls.email.hasError('required')" class="error-message">
                        Email address is required.
                    </mat-error>
                    <mat-error *ngIf="contactusForm.controls.email.hasError('email')" class="error-message">
                        Invalid email address.
                    </mat-error>
                    <mat-error *ngIf="contactusForm.controls.email.hasError('maxlength')" class="error-message">
                        Email cannot be more than {{ contactusForm.controls.email.getError('maxlength')['requiredLength'] }} characters long
                    </mat-error>
                </mat-form-field>
    
                <label class="form-label">Enter message here</label>
                <mat-form-field class="form-control" appearance="outline">
                    <textarea matInput [rows]="3" formControlName="message" placeholder="Enter message here"> </textarea>
                    <mat-error *ngIf="contactusForm.controls.message.hasError('required')" class="error-message">
                        Message is required.
                    </mat-error>
                    <mat-error *ngIf="contactusForm.controls.message.hasError('maxlength')" class="error-message">
                        Message cannot be more than {{ contactusForm.controls.message.getError('maxlength')['requiredLength'] }} characters long
                    </mat-error>
                </mat-form-field>
    
                <div *ngIf="readonlyMode" class="overlay"></div>
            </div>
            <button 
                *ngIf="!(isLoading$ | async)" 
                [type]="readonlyMode ? 'button' : 'submit'" 
                [matTooltip]="'Contact Us form can be submitted only from the public video page'"
                [matTooltipDisabled]="!readonlyMode"
                class="submit-btn" 
                color="primary" 
                mat-flat-button
            >
                <span>Submit</span>
            </button>
            <div *ngIf="isLoading$ | async" style="display: flex; justify-content: center;"> <mat-spinner [diameter]="32"></mat-spinner> </div>
        </form>
    
        <div *ngIf="isMessageSent$ | async" class="form-success-state-container">
            <openreel-logo size="90px">
                <mat-icon color="primary">checked</mat-icon>
            </openreel-logo>
            <h4>Your message has been sent</h4>
            <p>Your message has been received and we'll be in touch shortly</p>
        </div>
    </ng-container>
</or-widget-layout>