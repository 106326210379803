import { Injectable } from '@angular/core';
import { ImageColorService } from '@openreel/frontend/common/services/color-helpers/image-color.service';
import { FontDOMService } from '@openreel/frontend/common/services/font/font-dom.service';
import { Color, WHITE } from '@openreel/frontend/common/services/color-helpers/color.model';
import type {} from 'css-font-loading-module';
import { of } from 'rxjs';
import { BrandKit } from './brandkit.service';

@Injectable({ providedIn: 'root' })
export class BrandKitUtilityService {
  constructor(private colorService: ImageColorService, private fontDOMService: FontDOMService) {}

  loadFont(font: BrandKit['font']) {
    if (typeof FontFace === 'undefined') {
      return of();
    }

    return this.fontDOMService.addFontFaces([
      {
        id: font.id,
        family: font.family,
        source: font.source,
        variants: [
          {
            slug: 'regular',
            url: font.url,
          },
        ],
      },
    ]);
  }

  getLogoContrast(url: string, contrastWith: Color = WHITE) {
    return this.colorService.getImageContrast(url, contrastWith);
  }
}
