import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { CtaBrandKit, WidgetCustomData } from "../../hosting-interfaces";

@Component({
    selector: 'or-banner-widget-layout',
    templateUrl: './banner-widget-layout.component.html',
    styleUrls: ['./banner-widget-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerwidgetLayoutComponent {

    constructor(
        private cdr: ChangeDetectorRef,
    ) {}

    @Input() 
    get widgetData() { return this._widgetData }
    set widgetData(data: WidgetCustomData) {
        this.text = data?.text?.value ?? '';
        this.imageUrl = data?.imageUrl ?? null;
        this.cdr.detectChanges();
    }
    private _widgetData: WidgetCustomData

    @Input() 
    get brandKit() { return this._brandKit };
    set brandKit(data: CtaBrandKit) {
        this.bannerTextStyle = {
            'font-family': data?.font ?? 'initial',
        }
        this._brandKit = data;
        this.cdr.detectChanges();
    }
    private _brandKit?: CtaBrandKit;

    imageUrl: string | null = null;
    text: string = '';

    bannerTextStyle = { 'font-family': 'initial' };
} 