import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { KtdGridModule } from "@katoid/angular-grid-layout";
import { HostableGridLayoutComponent } from "./hostable-grid-layout.component";

@NgModule({
    declarations: [
        HostableGridLayoutComponent,
    ],
    imports: [
        CommonModule, 
        KtdGridModule,
    ],
    exports: [
        HostableGridLayoutComponent
    ]
})
export class HostablGridLayoutModule {}