export const ADMIT_USERS = 'admit-users';
export const AUTOMATIONS = 'automations';
export const BRANDING_AUTOFETCH = 'branding-autofetch';
export const CAPTIONS_TRANSLATION = 'captions-translation';
export const CAPTURE_ARCHIVE_CLIPS = 'capture-archive-clips';
export const CAPTURE_ARCHIVE_PROJECTS = 'capture-archive-projects';
export const CAPTURE_AUDIO_METER = 'capture-audio-meter';
export const CAPTURE_AUTOMATIC_HQ = 'capture-automatic-hq';
export const CAPTURE_CONTACT_UPDATE = 'contact-update';
export const CAPTURE_CREATE_TEAM = 'create_team';
export const CAPTURE_ENABLE_CONVERT_OLDGEN_SESSIONS = 'capture-enable-convert-oldgen-sessions';
export const CAPTURE_EXTERNAL_USER_LOGOUT = 'external-user-logout';
export const CAPTURE_LITE = 'capture-lite';
export const CAPTURE_LITE_EXACT_DEVICE_SELECTION = 'capture-lite-exact-device-selection';
export const CAPTURE_LITE_RECORDING_PREFERENCES = 'capture-lite-recording-preferences';
export const CAPTURE_LITE_SPEAKER_SELECTION = 'capture-lite-speaker-selection';
export const CAPTURE_LITE_VIRTUAL_BACKGROUND = 'capture-lite-virtual-background';
export const CAPTURE_OTP_2FA = 'otp-2fa';
export const CAPTURE_PRO_SESSION_MODAL_REDESIGN = 'capture-pro-session-modal-redesign';
export const CAPTURE_PRO_SPEAKER_SELECTION = 'capture-pro-speaker-selection';
export const CAPTURE_PRO_TEST_AUDIO_VIDEO = 'capture-pro-test-audio-video';
export const CAPTURE_SCHEDULED_SESSION_CREATION = 'scheduled-session-creation';
export const CAPTURE_SESSION_CLIPS = 'capture-session-clips';
export const CAPTURE_SHARING_PRESENTATIONS = 'capture-sharing-presentation';
export const CAPTURE_UGC_SESSION_CREATION = 'ugc-session-creation';
export const CAPTURE_UGC_SUBJECT = 'ugc-subject';
export const CAPTURE_VIDEO_EXTRACT_AUDIO = 'capture-video-extract-audio';
export const CHATTING = 'chatting';
export const COMMENTS_VISIBILITY = 'comments-visibility';
export const ENABLE_EMOJI_IN_CHAT = 'enable-emoji-in-chat';
export const GEO_LOCATION_JOIN_ROOM = 'geo-location-join-room';
export const GOOGLE_TAG_MANAGER_INTEGRATION = 'google-tag-manager-integration';
export const GROUPS_PERMISSIONS_SHARING = 'groups-permissions-sharing';
export const HOSTING = 'hosting';
export const HOSTING_ACCESS_VIDEO_DETAILS_WHILE_TRANSCODING = 'hosting-access-video-details-while-transcoding';
export const HOSTING_APPROVAL_WORKFLOWS = 'hosting-approval-workflows';
export const HOSTING_AUTOGENERATE_VIDEO_CAPTIONS = 'hosting-autogenerate-video-captions';
export const HOSTING_AUTOGENERATE_VIDEO_DESCRIPTION = 'hosting-autogenerate-video-description';
export const HOSTING_CAPTIONS_EDITOR = 'hosting-captions-editor';
export const HOSTING_CTA = 'hosting-cta';
export const HOSTING_DISABLE_ADD_TO_LIBRARY_MODAL = 'hosting-disable-add-to-library-modal';
export const HOSTING_HUB_ANALYTICS = 'hosting-hub-analytics';
export const HOSTING_INTERNAL_COMMENTS = 'hosting-internal-comments';
export const HOSTING_LAYOUTS = 'hosting-layouts';
export const HOSTING_MY_VIDEOS_MENU = 'hosting-my-videos-menu';
export const HOSTING_SINGLE_VIDEO_ANALYTICS = 'hosting-single-video-analytics';
export const HOSTING_SOCIAL_SHARE = 'hosting-social-share';
export const HOSTING_TEAM_WORKSPACE_MENU = 'hosting-team-workspace-menu';
export const HOSTING_VIDEO_APPROVALS = 'host-video-approvals';
export const HOSTING_VIDEO_CHAPTERS = 'hosting-video-chapters';
export const INTERNAL_COMMENT_ANNOTATIONS = 'internal-comment-annotations';
export const LIVE_STREAM_DESTINATION_FACEBOOK = 'live-stream-destination-facebook';
export const LIVE_STREAM_DESTINATION_YOUTUBE = 'live-stream-destination-youtube';
export const LIVE_STREAM_DESTINATIONS = 'live-stream-destinations';
export const OTP_2FA = 'otp-2fa';
export const OWNERSHIP_SHARING = 'ownership-sharing';
export const PUBLIC_COMMENTS = 'public-comments';
export const SEARCH_PAGE = 'search-page';
export const SELF_RECORD = 'self_record_in_project';
export const SELF_SIGNUP_UI_V2 = 'self-signup-ui-v2';
export const SOCIAL_USER_AUTH = 'social-user-auth';
export const SESSION_STREAM_TYPE = 'stream-type';
export const SESSION_STREAM_TYPE_ZOOM = 'stream-type-zoom';
export const SSO_SETUP_SCREEN = 'sso-setup-screen';
export const TELEPROMPTER = 'teleprompter';
export const TRANSCRIPTION = 'transcription';
export const TRANSCRIPTION_REPOPULATE_API = 'transcription-repopulate-api';
export const UGC_SESSION_LIBRARY_VIDEO_INSTRUCTION = 'ugc-session-library-video-instruction';
export const UPLOAD_WHILE_RECORDING = 'upload-while-recording';
export const USER_PILOT = 'user-pilot';
export const USER_UPLOAD = 'user-upload';
export const VIDEO_APPROVAL_HISTORY = 'video-approval-history';
export const VIDEO_ENCODE_TYPE = 'video-encode-type';
export const WORKFLOWS_ACCOUNT_BANNERS = 'workflows-account-banners';
export const WORKFLOWS_AI_ADJUSTMENTS = 'workflows-ai-adjustments';
export const WORKFLOWS_AI_COMPOSER = 'workflows-ai-composer';
export const WORKFLOWS_ASPECT_RATIOS = 'workflows-aspect-ratios';
export const WORKFLOWS_BLUEPRINT_TEMPLATES = 'workflows-blueprint-templates';
export const WORKFLOWS_BRAND_KITS = 'workflows-brand-kits';
export const WORKFLOWS_CREATOR = 'workflows-creator';
export const WORKFLOWS_CUSTOM_IMAGES = 'workflows-custom-images';
export const WORKFLOWS_GROUPS = 'workflows-groups';
export const WORKFLOWS_INSTANT_WIZARD = 'workflows-instant-wizard';
export const WORKFLOWS_OUTPUT_USE_NAMING_CONTENTION = 'workflows-output-use-naming-convention';
export const WORKFLOWS_RECORDING_BACKGROUNDS = 'workflows-recording-backgrounds';
export const WORKFLOWS_RENDER_TIMINGS = 'workflows-render-output-timings';
export const WORKFLOWS_SCENE_AUDIO = 'workflows-scene-audio';
export const WORKFLOWS_SCHEMA_UPGRADE = 'workflows-schema-upgrade';
export const WORKFLOWS_SKIA_MEASURER = 'workflows-skia-text-box-measurer';
export const WORKFLOWS_SKOTTIE = 'workflows-skottie';
export const WORKFLOWS_SOUNDTRACKS = 'workflows-soundtracks';
export const WORKFLOWS_TEMPLATE = 'workflows-template';
export const WORKFLOWS_TEMPLATE_BUILDER = 'workflows-template-builder';
export const WORKFLOWS_TEMPLATE_SWITCH = 'workflows-template-switch';
export const WORKFLOWS_TEMPLATE_TELEPROMPTER_SCRIPTS = 'workflows-template-telepropter-scripts';
export const WORKFLOWS_TEMPLATES_CACHE = 'workflows-templates-cache';
export const WORKFLOWS_TEXT_BOXES = 'workflows-text-boxes';
export const WORKFLOWS_TEXT_BOXES_EFFECTS = 'workflows-text-boxes-effects';
export const WORKFLOWS_TEXT_BOXES_LETTER_SPACING = 'workflows-text-boxes-letter-spacing';
export const WORKFLOWS_TRANSCODER_INPUT_VIDEOS_NORMALIZATION = 'workflows-transcoder-video-normalization';
export const WORKFLOWS_TRIMMER_TOKEN_EDITOR = 'workflows-trimmer-token-editor';
export const WORKFLOWS_ZOOM_UI = 'workflows-zoom-ui';
export const WORKFLOW_EDITOR_UI_V2 = 'workflow-editor-ui-v2';
export const YOUTUBE_INTEGRATION = 'youtube-integration';
export const ZOOM_INTEGRATION = 'zoom-integration';
export const PERMISSIONED_GROUPS = 'permissioned-groups';
