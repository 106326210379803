<div class="widget-layout-container">
    <div class="widget-layout-header">
        <h4 class="widget-layout-title">{{widgetName}}</h4>
        <div class="widget-layout-actions">
            <ng-content select="[widgetActions]"></ng-content>
        </div>
    </div>
    <div class="header-content-divider"></div>
    <div class="widget-layout-content">
        <ng-content select="[widgetContent]"></ng-content>

        <div *ngIf="isEnableConfigureMode" class="widget-layout-overlay"></div>
    </div>
</div>