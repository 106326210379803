import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ChapterListModule } from "../../components/chapter-list/chapter-list.module";
import { WidgetLayoutModule } from "../widget-layout/widget-layout.module";
import { ChaptersWidgetLayoutComponent } from "./chapters-widget-layout.component";

@NgModule({
    declarations: [ChaptersWidgetLayoutComponent],
    imports: [
        CommonModule,
        ChapterListModule,
        WidgetLayoutModule
    ],
    exports: [ChaptersWidgetLayoutComponent]
})
export class ChaptersWidgetLayoutModule {}