<div #layoutContainer>
  <ktd-grid 
    *ngIf="!!layoutConstraints"
    [cols]="cols"
    [rowHeight]="rowHeight"
    [layout]="layoutConstraints"
    (layoutUpdated)="changeConstraints($event)"
    (dragStarted)="setIsCustomizingGridItem(true)"
    (dragEnded)="setIsCustomizingGridItem(false)"
    (resizeStarted)="setIsCustomizingGridItem(true)"
    (resizeEnded)="setIsCustomizingGridItem(false)"
  >
    <ktd-grid-item 
      style="overflow: visible;"
      *ngFor="let item of layoutConstraints; trackBy:trackById"
      [id]="item.id"
      [resizable]="customizable"
      [draggable]="customizable"
    >
        <div class="widget-layout-container" [style.padding.px]="gridItemPadding">
          <ng-container *ngIf="item.id == WidgetName.VideoPlayer">
            <div #playerWidgetContainer class="player-widget-container">
              <ng-container *ngTemplateOutlet="contentTemplate; context: { widget: widgetsMap[item.id] }"></ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="item.id !== WidgetName.VideoPlayer">
            <ng-container *ngTemplateOutlet="contentTemplate; context: { widget: widgetsMap[item.id] }"></ng-container>
          </ng-container>
        </div>
    </ktd-grid-item>
  </ktd-grid>
</div>