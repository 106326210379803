<or-widget-layout [widgetName]="WidgetName.Playlist" [isEnableConfigureMode]="isEnableConfigureMode">
    <div widgetContent #contentEl>
            <ng-container *ngIf="videos?.length; else emptyTemplate">
                <or-infinite-scroll
                    class="infinite-scroll-container"
                    (page)="onLoadMore()"
                    [length]="total"
                    [pageSize]="pageSize"
                    [pageIndex]="pageIndex"
                    [nextContentLoaded]="!loading"
                >
                    <a 
                        *ngFor="let video of videos; trackBy: trackByVideoId;" 
                        class="playlist-item" 
                        (click)="onClickVideo($event, video)"
                        [class.md]="containerSize == 'md'"
                        [class.sm]="containerSize == 'sm'"
                    >
                        <div class="thumbnail">
                            <img alt="Not found" [src]="video.thumbnailUrl ?? video.thumbnailSignedUrl?.[0] ?? 'assets/common/images/placeholder16x9.gif'" />
                            <div *ngIf="containerSize != 'lg'" class="play-backdrop">
                                <mat-icon>play_arrow</mat-icon>
                            </div>
                        </div>
                        <div class="title">
                            <p [matTooltip]="video.title" [matTooltipShowDelay]="1500">{{video.title}}</p>
                        </div>
                        <div *ngIf="containerSize == 'lg'" class="actions">
                            <button type="button">
                                <mat-icon>play_arrow</mat-icon>
                            </button>
                        </div>
                    </a>
                </or-infinite-scroll>
            </ng-container>

            <ng-template #emptyTemplate>
                <div class="empty-template-container">
                  <openreel-logo size="90px" color="#D8DAE5">
                    <mat-icon>search</mat-icon>
                  </openreel-logo>
                    <p>No other videos found</p>
                    <p>New videos added will appear here</p>
                </div>
            </ng-template>

            <mat-spinner [diameter]="36" *ngIf="loading" style="margin: 0 auto"></mat-spinner>
    </div>
</or-widget-layout>