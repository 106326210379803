import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../environments/environment';
import { EventName, EventProperties } from './analytics.interfaces';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  prefix = 'Analytics: ';

  private _isInitialized: boolean = false;

  private id: string;
  private title: string;
  private accountId: string;
  private hubId: string;
  private logEvents: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.logEvents = isPlatformBrowser(platformId);
  }

  init(prefix?:string): void {
    if(!this._isInitialized) {
      mixpanel.init(environment.hostingMixpanelToken);

      if(prefix){
        this.prefix = prefix;
      }

      this._isInitialized = true;
    }
  }

  setId(id: string) {
    this.id = id;
  }

  setTitle(title: string) {
    this.title = title;
  }

  setHub(hubId:string) {
    this.hubId = hubId;
  }

  setAccount(accountId:string) {
    this.accountId = accountId;
  }

  private _logEvent(eventName: EventName, description: string, properties?: EventProperties): void {
    if (this.logEvents && this._isInitialized) {
      if(description){
        description = this.prefix + description;
      }
  
      if (properties && description) {
        properties = {
          ...properties,
          description
        };
      }
  
      const eventProperties = {};
      if (properties) {
        for (const key in properties) {
          if (properties[key] != undefined && properties[key] != null) {
            eventProperties[`#${key}`] = properties[key];
          }
        }
      }
  
      mixpanel.track(eventName, eventProperties);
  
      mixpanel.people.increment('^total_event_count', 1);
      mixpanel.people.set({ '^last_event': new Date() });
    }
  }

  logEvent(eventName: EventName, description: string, properties?: EventProperties) {
    this._logEvent(eventName, description, {
      ...properties,
      id: properties?.id ?? this.id,
      account_id: properties?.account_id ?? this.accountId,
      title: properties?.title ?? this.title,
      hub_id: properties?.hub_id ?? this.hubId,
    })
  }
}
