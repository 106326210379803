import { Injectable } from '@angular/core';
import { StoreSubject } from '@openreel/frontend/common/classes/store-subject';
import { map, catchError } from 'rxjs/operators';
import { HubService } from '../services/hub.service';
import { PublicHostedVideo, PublicHub } from '../interfaces';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';

const PAGE_SIZE = 8;

const INIT = {
  page: 0,
  total: 1,
  pageSize: PAGE_SIZE,
  videos: null,
  slug: '',
  idHash: '',
  title: '',
  updatedAt: '',
  loading: false,
  hub: null,
  scrollTop: 0,
};

type HostingListViewInit = typeof INIT;

@Injectable({
  providedIn: 'root'
})
export class HostingListViewService {
  private hostingListState = new StoreSubject<HostingListViewInit>(INIT);

  readonly hub$: Observable<PublicHub | null> = this.hostingListState.select('hub');
  readonly scrollTop$: Observable<number> = this.hostingListState.select('scrollTop');

  videos$: Observable<PublicHostedVideo[]> = this.hostingListState.select('videos');
  loading$: Observable<boolean> = this.hostingListState.select('loading');
  page$: Observable<number> = this.hostingListState.select('page');
  total$: Observable<number> = this.hostingListState.select('total');
  pageSize$: Observable<number> = this.hostingListState.select('pageSize');
  hubSlug$: Observable<string> = this.hostingListState.select('slug');

  constructor(private hubService: HubService, private router: Router) {}

  setHub(hub: PublicHub) {
    this.hostingListState.modify(hub, (state, h) => {
      return {
        ...state,
        hub,
        videos: null,
        title: h.title,
        slug: h.slug,
        idHash: h.idHash,
        updatedAt: h.updatedAt,
        loading: false,
        scrollTop: 0,
      };
    });
    this.loadPage();
  }

  nextPage() {
    this.hostingListState.modify(undefined, (state) => {
      if (state.total <= state.page * PAGE_SIZE) {
        return state;
      }
      return {
        ...state,
        page: state.page + 1,
      };
    });
    this.loadPage();
  }

  updateScrollPosition(scrollTop: number): void {
    this.hostingListState.modify(scrollTop, (state) => {
      return {
        ...state,
        scrollTop,
      };
    });
  }

  private loadPage() {
    this.setLoading();
    this.hostingListState.modify(undefined, (state) => {
      if (state.total > state.page * PAGE_SIZE) {
        return this.hubService.getAllHostedVideos4Hub(state.idHash, state.page, PAGE_SIZE, state.updatedAt).pipe(
          map((res) => {
            return {
              ...state,
              loading: false,
              videos: (state.videos || []).concat(res.rows),
              total: res.count,
            };
          }),
          catchError(() => {
            this.router.navigateByUrl('/something-wrong');
            return of(state);
          })
        );
      }
      return {
        ...state,
        loading: false,
      };
    });
  }

  private setLoading() {
    this.hostingListState.modify(undefined, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
  }
}
