import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { commonenv } from "@openreel/frontend/common/env/environment";
import { Observable } from "rxjs";
import { SendEmailDto } from "../../hosting-interfaces";

@Injectable()
export class ContactUsService {
    private baseUrl = commonenv.nextGenApiUrl;

    constructor(
        private http: HttpClient
    ) {}

    sendEmail(dto: SendEmailDto): Observable<void> {
        const { layoutId, ...rest } = dto;
        return this.http.post<void>(`${this.baseUrl}hosting/public/layouts/${layoutId}/send-mail`, rest);
    }
}