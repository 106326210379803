import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { ErrorTypes } from './error-page/error-page.component';

export abstract class BaseErrorHandlingResolver<T> implements Resolve<T> {
  constructor(private router: Router) {}

  abstract resolve(route: ActivatedRouteSnapshot): Observable<T>;

  protected handleError(e: HttpErrorResponse, redirect?: string, queryParams?: Record<string, string>) {
    if (e?.status === 404) {
      this.router.navigateByUrl(`/${ErrorTypes.NotFound}`);
    } else if (e?.status === 403) {
      this.router.navigateByUrl(`/${ErrorTypes.Unauthorized}`);
    } else if (e?.status === 400) {
      this.router.navigate([`/${ErrorTypes.BadRequest}`], { queryParams: { message: e['error']?.message } });
    } else if (e?.status === 401) {
      this.router.navigate([`/verify-password/${redirect}`], { queryParams, queryParamsHandling: 'merge' });
    } else {
      this.router.navigateByUrl(`/${ErrorTypes.SomethingWrong}`);
    }
    return EMPTY;
  }
}
