import { DOCUMENT } from "@angular/common";
import { Directive, HostListener, Inject, Input } from "@angular/core";
import { EventName } from "./analytics.interfaces";
import { AnalyticsService } from "./analytics.service";

@Directive({
    selector: '[openreelPageUnloadEventCapture]'
})
export class PageUnloadEventCaptureDirective {
    @Input()
    pageUnloadEventName: EventName = 'public_page_left';

    @Input()
    pageUnloadEventDescription: string = 'Leaving public page'

    constructor(
        private analyticsService: AnalyticsService,
        @Inject(DOCUMENT) private document: Document,
    ) { }

    @HostListener('document:visibilitychange')
    onVisibilityChange() {
        if(this.document.visibilityState === 'hidden') {
            this.analyticsService.logEvent(this.pageUnloadEventName, this.pageUnloadEventDescription);
        }
    }
}