import { Directive, HostListener, Input } from "@angular/core";
import { EventName, EventProperties } from "./analytics.interfaces";
import { AnalyticsService } from "./analytics.service";

@Directive({
    selector: 'input[openreelTextTypedEventCapture]'
})
export class TextTypedEventCaptureDirective {
    @Input() textTypedEventName: EventName = 'public_ui_password_typed';
    @Input() textTypedEventDescription: string = 'Password typed';
    @Input() textTypedEventProperties?: EventProperties;

    private _reported2Analytics: boolean = false;

    constructor(
        private analyticsService: AnalyticsService,
    ) {}

    @HostListener('input')
    onInput() {
        if(!this._reported2Analytics) {
            this.analyticsService.logEvent(this.textTypedEventName, this.textTypedEventDescription, this.textTypedEventProperties);
            this._reported2Analytics = true;
        }
    }
}