import { Directive, Input, OnInit } from "@angular/core";
import { AnalyticsService } from "./analytics.service";

@Directive({
    selector: '[openreelInitAnalytics]'
})
export class InitAnalyticsDirective implements OnInit {
    @Input('openreelInitAnalytics')
    prefix?: string;

    constructor(
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.analyticsService.init(this.prefix);
    }
}