import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PublicHostedVideo, PublicHub } from '../interfaces';
import { PasswordTokenService } from '../services/password-token.service';
import { AnalyticsHttpContextTokens } from '../analytics/constants';

@Injectable({providedIn: 'root'})
export class HubService {
  private baseUrl = environment.nextGenApiUrl;

  constructor(private http: HttpClient, private passwordTokenService: PasswordTokenService) { }

  // Get Hub details
  getHub(idHash: string, slug: string) {
    let headers: { [name: string]: string | string[] } = { 'Content-Type': 'application/json' };
    if (this.passwordTokenService.getHubToken()) {
      headers = { ...headers, token: this.passwordTokenService.getHubToken() };
    }
      return this.http.get<PublicHub>(`${this.baseUrl}hosting/public/hubs/${idHash}/${slug}`,{ headers });
  }

  // Get All hostable videos by given Hub id
  getAllHostedVideos4Hub(idHash: string, pageNumber: number, pageSize: number, updatedAt: string, sort?: string) {
    let headers: { [name: string]: string | string[] } = { 'Content-Type': 'application/json' };
    if (this.passwordTokenService.getHubToken()) {
      headers = { ...headers, token: this.passwordTokenService.getHubToken() };
    }

    let params = new HttpParams()
                    .set('page', pageNumber.toString())
                    .set('perPage', pageSize.toString())
                    .set('updatedAt', updatedAt);

    if (sort)
        params = params.set('sort', sort);

    return this.http.get<{count:number, rows: PublicHostedVideo[]}>(`${this.baseUrl}hosting/public/hubs/${idHash}/videos`, { params, headers });
  }

  verifyPassword(idHash: string, password: string) {
    return this.http.post<{ token: string }>(
      `${this.baseUrl}hosting/public/hubs/${idHash}/verify-password`,
      {password: password},
      {
        context: new HttpContext()
                  .set(AnalyticsHttpContextTokens.ENABLE_EVENT_CAPTURE, true)
                  .set(AnalyticsHttpContextTokens.SUCCESS_EVENT_DATA, { name: 'public_ui_password_success', description: 'Password is correct', properties: { hub_id: idHash } })
                  .set(AnalyticsHttpContextTokens.FAIL_EVENT_DATA, { name: 'public_ui_password_fail', description: 'Password is wrong', properties: { hub_id: idHash } })
      }
    );
  }
}
