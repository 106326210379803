import { NgModule } from "@angular/core";
import { HubEventCaptureDirective } from "./hub-event-capture.directive";
import { InitAnalyticsDirective } from "./init-analytics.directive";
import { PageUnloadEventCaptureDirective } from "./page-unload-event-capture.directive";
import { HostingPlayerEventCaptureDirective } from "./player-event-capture.directive";
import { TextTypedEventCaptureDirective } from "./text-typed-event-capture.directive";

@NgModule({
    declarations: [
        HostingPlayerEventCaptureDirective,
        PageUnloadEventCaptureDirective,
        HubEventCaptureDirective,
        TextTypedEventCaptureDirective,
        InitAnalyticsDirective,
    ],
    imports: [

    ],
    exports: [
        HostingPlayerEventCaptureDirective,
        PageUnloadEventCaptureDirective,
        HubEventCaptureDirective,
        TextTypedEventCaptureDirective,
        InitAnalyticsDirective,
    ]
})
export class AnalyticsModule {}