import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { UserDetails } from '../interfaces';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  readonly logoutSubject = new Subject<void>();

  constructor(
    private readonly cookieService: CookieService,
    private readonly httpClient: HttpClient,
  ) {
    this.restore();
  }

  private cookieName = 'openreel-user';
  public isAuthenticated$ = new BehaviorSubject(false);
  private _user$ = new BehaviorSubject<UserDetails | null>(null);
  public user$ = this._user$.asObservable();

  private urls = {
    logout: environment.nextGenApiUrl + 'logout',
  }

  get user() {
    return this._user$.value;
  }

  restore() {
    const user = this.getUserDetails();
    if (user) {
      this.isAuthenticated$.next(true);
      this._user$.next(user);
    } else {
      this.isAuthenticated$.next(false);
      this._user$.next(null);
    }
  }

  isLoggedIn() {
    return this.isAuthenticated$.getValue();
  }

  getUserDetails(): UserDetails | null {
    return JSON.parse(this.getCookie());
  }

  getCookie() {
    const cookie = this.cookieService.get(this.cookieName);
    return cookie.length ? cookie : null;
  }

  resetActiveSession(): void {
    this.logout().subscribe(() => {
      this.logoutSubject.next();
    });

    this.invalidateToken();
  }

  invalidateToken() {
    console.log('invalidate token');
    this.isAuthenticated$.next(false);
    this._user$.next(null);
    this.unsetCookie();
  }

  unsetCookie() {
    const host = this.getCookieHost();
    this.cookieService.delete(this.cookieName, '/', host, environment.production ? true : false);
  }

  logout(): Observable<void> {
    return this.httpClient.get<void>(this.urls.logout);
  }

  getCookieHost() {
    /**
     * The Cookie should be available for *.host.com.
     * To support both capture and workflows
     */
    let host = window.location.host;
    const hostParts = host.split('.');
    if (hostParts.length > 2) {
      /**
       * Not localhost and not host.com, at least subdomain.host.com
       *  */
      hostParts.shift();
    }
    host = hostParts.join('.');

    /**
     * Remove port
     *  */
    host = host.split(':')[0];
    return host;
  }
}
