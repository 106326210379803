import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeaderTitleService } from './header-title.service';
import { map, startWith } from 'rxjs/operators';
import { BrandkitService } from '../brandkits/brandkit.service';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'openreel-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  title$ = this.headerTitleService.headerHubTitle$;
  videoTitle$ = this.headerTitleService.headerVideoTitle$;
  slug$ = this.headerTitleService.headerHubSlug$;
  hubIdHash$ = this.headerTitleService.headerHubIdHash$;

  gtSmallScreen$ = this.breakpointObserver.observe('(min-width: 769px)').pipe(map(({ matches }) => matches));

  logo$ = this.brandkitService.logo$.pipe(
    startWith({
      url: '',
      styles: {},
    })
  );

  isUserLoggedIn$ = this.authService.isAuthenticated$;

  userDetails = this.authService.getUserDetails()?.data;
  constructor(
    private headerTitleService: HeaderTitleService,
    private brandkitService: BrandkitService,
    private authService: AuthService,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {}


  goTo(path: string) {
    location.href = `${environment.nextGenAppUrl}${path}`;
  }

  onLogout(): void {
    this.authService.resetActiveSession();
  }
}
