/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({ selector: '[orRecreateOnChange]', standalone: true })
export class RecreateOnChangeDirective implements OnInit {
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  @Input() set orRecreateOnChange(data: any) {
    if (data != this._data) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
      this._isRendered = true;
      this._data = data;
    }
  }
  private _data: any = undefined;
  private _isRendered = false;

  ngOnInit(): void {
    if (!this._isRendered) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
