import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';

if (environment.production) {
  enableProdMode();
}

if(environment.sentryDSN){
  Sentry.init({
    dsn: environment.sentryDSN,
    environment: environment.environmentName,
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: [new RegExp('^' + environment.nextGenApiUrl)],

    tracesSampleRate: environment.sentryTracesSampleRate,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
