import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseErrorHandlingResolver } from './base-error-handling.resolver';
import { HubService } from './services/hub.service';
import { PublicHub } from './interfaces';
import { MetaService } from './meta.service';
import { getSlugFromIdentifier, getIdHashFromIdentifier } from '@openreel/frontend/common/hosting/hosting-identifier.utils';

@Injectable({ providedIn: 'root' })
export class HubResolver extends BaseErrorHandlingResolver<PublicHub> {
  constructor(
    private hubService: HubService,
    router: Router,
    private metaService: MetaService,
  ) {
    super(router);
  }

  resolve(route: ActivatedRouteSnapshot): Observable<PublicHub> {
    const identifier = route.params['identifier'];
    const idHash = getIdHashFromIdentifier(identifier);
    const slug = getSlugFromIdentifier(identifier);

    return this.hubService
      .getHub(idHash, slug)
      .pipe(
        tap((d) => {
          this.metaService.addMetaForHub(d);
        }),
        catchError((e: HttpErrorResponse) => this.handleError(e, `hub/${idHash}-${slug}`))
      );
  }
}
