import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PasswordTokenService {

  readonly videoTokens = new Map<string, string>();

  hubToken: string;

  storeVideoToken(idHash: string, token: string | null): void {
    this.videoTokens.set(idHash, token);
  }

  getVideoToken(idHash: string): string | null {
    return this.videoTokens.get(idHash);
  }

  storeHubToken(token: string): void {
    this.hubToken = token;
  }

  getHubToken(): string {
    return this.hubToken;
  }
}
