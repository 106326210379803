import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ChapterListComponent } from './chapter-list.component';
import { OpenreelLogoModule } from '@openreel/ui/openreel-logo/openreel-logo.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ParseDurationPipe } from '@openreel/frontend/common/pipes/parse-duration.pipe';
import { FormatDurationPipe } from '@openreel/frontend/common/pipes/format-duration.pipe';

@NgModule({
  declarations: [ChapterListComponent],
  imports: [CommonModule, ParseDurationPipe, FormatDurationPipe, MatIconModule, OpenreelLogoModule, MatTooltipModule],
  exports: [ChapterListComponent],
})
export class ChapterListModule {}
