import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, from, of, ReplaySubject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, mapTo, shareReplay, switchMap } from 'rxjs/operators';
import { BrandKitUtilityService } from './brandkit-utility.service';
import { environment } from '../../environments/environment';
import { getIdHashFromIdentifier } from '@openreel/frontend/common/hosting/hosting-identifier.utils';


export interface BrandKit {
  logo: {
    url: string;
  };
  font: {
    id: number;
    source: 'google' | 'custom';
    family: string;
    url?: string;
  };
}

const MIN_CONTRAST = 5;

const DEFAULT_BRANDKIT: BrandKit = {
  logo: {
    url: '/assets/common/wordmarks/blue-gradient.svg',
  },
  font: null,
};

@Injectable({ providedIn: 'root' })
export class BrandkitService {
  constructor(
    private http: HttpClient,
    private bkUtility: BrandKitUtilityService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  private baseUrl = environment.nextGenApiUrl;
  private brandkitSource = new ReplaySubject<BrandKit>(1);

  brandKit$ = this.brandkitSource.asObservable();

  font$ = this.brandKit$.pipe(
    filter((bk) => !!bk.font),
    map((bk) => bk.font),
    distinctUntilChanged((x, y) => x.url === y.url && x.family === y.family),
    switchMap((font) => from(this.bkUtility.loadFont(font)).pipe(map(() => font))),
    map((font) => ({
      'font-family': font.family,
    })),
    catchError(() => {
      return of({
        'font-family': '"Roboto", sans-serif',
      });
    }),
    shareReplay(1),
  );

  logo$ = this.brandKit$.pipe(
    map((bk) => bk.logo.url),
    distinctUntilChanged((x, y) => x.slice(0, x.lastIndexOf('/')) === y.slice(0, y.lastIndexOf('/'))),
    switchMap((logoUrl) => {
      const logoLoad$ = this.bkUtility.getLogoContrast(logoUrl).pipe(
        catchError(() => of({ ratio: 21 })),
        map((contrast) => {
          let filter: string;
          if (contrast.ratio < MIN_CONTRAST) {
            filter = `drop-shadow(0px 1px 1px #000)`;
          }
          return {
            url: logoUrl,
            styles: {
              filter,
            },
          };
        })
      );
      return logoUrl ? logoLoad$ : of({ ...DEFAULT_BRANDKIT.logo, styles: {} });
    }),
    shareReplay(1)
  );

  brandKitLoaded$ = combineLatest([this.font$, this.logo$]).pipe(mapTo(true), first(), shareReplay(1));

  private getBrandkit(type: 'hubs' | 'videos', idHash: string) {
    return this.http.get<BrandKit>(`${this.baseUrl}hosting/public/${type}/${idHash}/brandkit`);
  }

  loadBrandkit() {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        first(),
        switchMap((e: NavigationEnd) => {
          const type = e.urlAfterRedirects.includes('hub/') ? 'hubs' : 'videos';
          const idHash = getIdHashFromIdentifier(this.route.snapshot.firstChild.firstChild.params['identifier']);
          return type && idHash ? this.getBrandkit(type, idHash) : of(DEFAULT_BRANDKIT);
        })
      )
      .subscribe(
        (bk) => this.brandkitSource.next(bk),
        () => this.brandkitSource.next(DEFAULT_BRANDKIT),
      );
  }
}
