import { NgModule } from "@angular/core";
import { HostablGridLayoutModule } from "./hostable-grid-layout/hostable-grid-layout.module";
import { ChaptersWidgetLayoutModule } from "./chapters-widget-layout/chapters-widget-layout.module";
import { ContactusWidgetLayoutModule } from "./contactus-widget-layout/contactus-widget-layout.module";
import { PlaylistWidgetLayoutModule } from "./playlist-widget-layout/playlist-widget-layout.module";
import { BannerWidgetLayoutModule } from "./banner-widget-layout/banner-widget-layout.module";

@NgModule({
    imports: [
        HostablGridLayoutModule,
        ChaptersWidgetLayoutModule,
        ContactusWidgetLayoutModule,
        PlaylistWidgetLayoutModule,
        BannerWidgetLayoutModule,
    ],
    exports: [
        HostablGridLayoutModule,
        ChaptersWidgetLayoutModule,
        ContactusWidgetLayoutModule,
        PlaylistWidgetLayoutModule,
        BannerWidgetLayoutModule,
    ]
})
export class HostableLayoutModule {}