import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { WidgetLayoutModule } from "../widget-layout/widget-layout.module";
import { ContactUsWidgetLayoutComponent } from "./contactus-widget-layout.component";
import {MatTooltipModule} from '@angular/material/tooltip';
import { OpenreelLogoModule } from '@openreel/ui/openreel-logo/openreel-logo.module';

@NgModule({
    declarations: [ContactUsWidgetLayoutComponent],
    imports: [
        CommonModule,
        WidgetLayoutModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        OpenreelLogoModule,
    ],
    exports: [ContactUsWidgetLayoutComponent]
})
export class ContactusWidgetLayoutModule {}