import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { PublicHostedVideo, PublicHub } from './interfaces';
import { environment } from '../environments/environment';

const PASSWORD_PROTECTED_THUMBNAIL = `${environment.hostingAppUrl}assets/images/password-protected-thumbnail.png`;

@Injectable({providedIn: 'root'})
export class MetaService {
    private isServer: boolean;
    constructor(
        private meta: Meta,
        @Inject(PLATFORM_ID) platformId: string
    ) {
        this.isServer = isPlatformServer(platformId);
    }

    private addCommonMeta(obj: PublicHostedVideo|PublicHub) {
        this.meta.addTag({
            property: 'og:site_name',
            content: 'OpenReel',
        });
        this.meta.addTags([
            {
                property: 'og:type',
                content: 'website',
            },
            {
                property: 'og:site_name',
                content: 'OpenReel',
            },
            {
                property: 'og:title',
                content: obj.title,
            },
            {
                property: 'twitter:card',
                content: 'summary_large_image',
            },
            {
                property: 'twitter:site',
                content: '@openreelvideo',
            },
        ]);
    }

    addMetaForVideo(video: PublicHostedVideo) {
        if (this.isServer) {
            this.addCommonMeta(video);
            this.meta.addTags([
                {
                    property: 'og:image',
                    content: video.thumbnailSignedUrl[0],
                },
                {
                    property: 'og:image:secure_url',
                    content: video.thumbnailSignedUrl[0],
                },
                {
                    property: 'og:description',
                    content: video.description,
                },
                {
                    property: 'og:url',
                    content: `${environment.hostingAppUrl}${video.idHash}-${video.slug}`,
                },
                {
                    property: 'og:image:alt',
                    content: video.altText || video.title,
                },
                {
                    property: 'twitter:image',
                    content: video.thumbnailSignedUrl[0],
                },
                {
                    property: 'twitter:image:alt',
                    content: video.altText || video.title,
                },
            ]);
        }
    }

    addMetaForHub(hub: PublicHub) {
        if (this.isServer) {
            this.addCommonMeta(hub);
            const metaTags = [
                {
                    property: 'og:url',
                    content: `${environment.hostingAppUrl}hub/${hub.idHash}-${hub.slug}`,
                },
            ];
            if (hub.thumbnailSignedUrl) {
                metaTags.push({
                    property: 'og:image',
                    content: hub.thumbnailSignedUrl,
                });
                metaTags.push({
                    property: 'og:image:secure_url',
                    content: hub.thumbnailSignedUrl,
                });
                metaTags.push({
                    property: 'twitter:image',
                    content: hub.thumbnailSignedUrl,
                });
            }
            if (hub.videoCount) {
                metaTags.push({
                    property: 'og:description',
                    content: hub.videoCount === 1 ? '1 video ready for you to watch!' : `Collection of ${hub.videoCount} videos ready for you to watch!`,
                });
            }
            this.meta.addTags(metaTags);
        }
    }

    addVerifyPasswordPageMeta(idHash: string, slug: string, assetType: 'video' | 'hub'): void {
        if (this.isServer) {
            const metaTags = [
                {
                    property: 'og:image',
                    content: PASSWORD_PROTECTED_THUMBNAIL,
                },
                {
                    property: 'og:image:secure_url',
                    content: PASSWORD_PROTECTED_THUMBNAIL,
                },
                {
                    property: 'og:image:alt',
                    content: slug,
                },
                {
                    property: 'twitter:image',
                    content: PASSWORD_PROTECTED_THUMBNAIL,
                },
                {
                    property: 'twitter:image:alt',
                    content: slug,
                },
            ];

            if (assetType === 'video') {
                metaTags.push({
                    property: 'og:url',
                    content: `${ environment.hostingAppUrl }${ idHash }-${ slug }`,
                });
            } else if (assetType === 'hub') {
                metaTags.push({
                    property: 'og:url',
                    content: `${ environment.hostingAppUrl }hub/${ idHash }-${ slug }`,
                });
            }

            this.meta.addTags(metaTags);
        }
    }
}
