<div class="header-container">
    <div class="logo-container">
        <div class="logo" *ngIf="logo$ | async as logo" [ngStyle]="logo.styles">
            <img *ngIf="logo.url" [src]="logo.url" crossorigin="" alt="company logo" />
        </div>
        <div
            class="logo-details"
            *ngIf="{ slug: slug$ | async, hubTitle: title$ | async, videoTitle: videoTitle$ | async, hubIdHash: hubIdHash$ | async } as hd"
        >
            <div class="breadcrumbs">
                <div class="hub-title" *ngIf="hd.hubTitle">
                    <a class="hub-link" routerLink="/hub/{{hd.hubIdHash}}-{{ hd.slug }}" [class.no-click]="!hd.videoTitle">
                        {{ hd.hubTitle ? hd.hubTitle : hd.videoTitle }}
                    </a>

                    <mat-icon class="breadcrumb-icon" *ngIf="hd.hubTitle && hd.videoTitle">chevron_right</mat-icon>
                </div>

                <div *ngIf="hd.videoTitle" class="video-title no-click">
                  {{ hd.videoTitle }}
                </div>

                <a
                    *ngIf="hd.videoTitle && hd.hubTitle"
                    routerLink="/hub/{{hd.hubIdHash}}-{{ hd.slug }}"
                    mat-button
                    color="primary"
                    class="back-btn"
                >
                    <mat-icon>chevron_left</mat-icon>
                    <span>Back to hub</span>
                </a>
            </div>
        </div>
    </div>
    <div class="header-actions">
        <button mat-stroked-button (click)="goTo('login')" *ngIf="!(isUserLoggedIn$ | async); else userLoggedIn">
            Login {{ (gtSmallScreen$ | async) ? 'to OpenReel' : '' }}
        </button>
        <ng-template #userLoggedIn>
            <div class="user-details">
                <div class="avatar">
                    <img src="assets/common/images/logo.png" alt="user image">
                </div>
                <div class="username" [mat-menu-trigger-for]="accountMenu">
                    <span *ngIf="gtSmallScreen$ | async">{{ userDetails.loggedin_fullname }}</span>
                    <mat-icon inline="true">keyboard_arrow_down</mat-icon>
                </div>
                <mat-menu class="header-menu" #accountMenu="matMenu">
                    <button mat-menu-item class="menu-item" (click)="goTo('dashboard')">Go to Openreel</button>
                    <a href="https://helpcenter.openreel.com/hc/en-us" class="menu-item" target="blank" mat-menu-item>Help</a>
                    <button mat-menu-item class="menu-item" (click)="onLogout()">Logout</button>
                </mat-menu>
            </div>
        </ng-template>
    </div>
</div>
