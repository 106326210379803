import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { getIdHashFromIdentifier } from '@openreel/frontend/common/hosting/hosting-identifier.utils';
import {
  ScriptLoaderService,
  ScriptResolve,
} from '@openreel/frontend/common/services/script-loader/script-loader.service';
import { EMPTY, Observable, of, switchMap } from 'rxjs';
import { catchError, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../environments/environment';

type GtmCode = string | null;

export interface GoogleTagManagerState {
  gtmCode: GtmCode;
}

const DEFAULT_STATE: GoogleTagManagerState = {
  gtmCode: null,
};

@Injectable({ providedIn: 'root' })
export class GoogleTagManagerService extends ComponentStore<GoogleTagManagerState> {

  readonly gtmCode$ = this.select(state => state.gtmCode);
  readonly setGtmCode = this.updater((state, gtmCode: GtmCode) => ({ ...state, gtmCode }));

  private readonly scriptLoaderService = inject(ScriptLoaderService);

  private readonly baseUrl = environment.nextGenApiUrl;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly http: HttpClient,
  ) {
    super(DEFAULT_STATE);
  }

  readonly requestGtmCode = this.effect(($: Observable<void>) => $.pipe(
    switchMap(() => {
      return this.router.events
        .pipe(
          withLatestFrom(this.gtmCode$),
          filter(([e, _]) => (e instanceof NavigationEnd)),
          switchMap(([e, gtmCode]: [NavigationEnd, string]) => {
            if (gtmCode) {
              return of(gtmCode);
            }

            try {
              const type = e.urlAfterRedirects.includes('hub/') ? 'hubs' : 'videos';

              const routeFirstChild = this.route.snapshot.firstChild;
              const identifier = routeFirstChild.params['identifier'] ?? routeFirstChild.firstChild.params['identifier'];
              const idHash = getIdHashFromIdentifier(identifier);

              return (type && idHash) ? this.getGtmCode(type, idHash).pipe(
                tap((gtmCode) => {
                  this.setGtmCode(gtmCode);
                }),
                catchError(() => {
                  this.setGtmCode(null);

                  return EMPTY;
                })
              ) : of(null);
            } catch (e) {
              return of(null);
            }
          })
        );
    }),
  ))

  readonly initGoogleTagManagerScripts = this.effect((_: Observable<void>) => this.gtmCode$.pipe(
    switchMap(gtmCode => {
      if (gtmCode) {
        return this.initializeGoogleTagManagerScripts(gtmCode);
      } else {
        return of(null);
      }
    })
  ));

  private initializeGoogleTagManagerScripts(googleTag: GtmCode): Observable<ScriptResolve[]> {
    return this.scriptLoaderService.load([
      {
        name: 'gtm-inline',
        src: null,
        content: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${googleTag}');
        `,
      }
    ]);
  }

  private getGtmCode(type: 'hubs' | 'videos', idHash: string): Observable<GtmCode> {
    return this.http.get<{ gtmCode: GtmCode }>(`${this.baseUrl}hosting/public/${type}/${idHash}/gtag`).pipe(
      map(({ gtmCode }) => gtmCode),
    );
  }

}
