import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { of } from 'rxjs';
import { environment } from '../../environments/environment';
import { IVideoService, PublicHostedVideo } from '../interfaces';
import { PasswordTokenService } from '../services/password-token.service';
import { AnalyticsHttpContextTokens } from '../analytics/constants';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class VideoService implements IVideoService {
  private baseUrl = environment.hostingPlaybackApiUrl;
  constructor(
    private http: HttpClient,
    private transferState: TransferState,
    private passwordTokenService: PasswordTokenService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  getVideoData(idHash: string, slug: string, isEmbed: boolean) {
    const key = makeStateKey<PublicHostedVideo>(`videos-${slug}`);
    if (this.transferState.hasKey(key)) {
      return of(this.transferState.get(key, undefined));
    }
    let headers: { [name: string]: string | string[] } = { 'Content-Type': 'application/json' };

    const videoToken = this.passwordTokenService.getVideoToken(idHash);

    if (videoToken) {
      headers = { ...headers, token: videoToken };
    }

    const httpOptions = {
      headers: new HttpHeaders(headers),
      withCredentials: true,
    };

    return this.http.get<PublicHostedVideo>(
      `${this.baseUrl}hosting/public/videos/${idHash}/${slug}?embed=${isEmbed}&hostname=${this.getHostname()}`,
      httpOptions
    );
  }

  getHostname(): string {
    const siteDomain = this.document.referrer;
    let hostname: string;
    if (siteDomain) {
      try {
        hostname = new URL(siteDomain).hostname;
      } catch (err) {}
    }
    return hostname;
  }

  verifyPassword(idHash: string, password: string) {
    return this.http.post<{ token: string }>(
      this.baseUrl + 'hosting/public/videos/' + idHash + '/verify-password',
      { password: password },
      {
        context: new HttpContext()
          .set(AnalyticsHttpContextTokens.ENABLE_EVENT_CAPTURE, true)
          .set(AnalyticsHttpContextTokens.SUCCESS_EVENT_DATA, {
            name: 'public_ui_password_success',
            description: 'Password is correct',
            properties: { id: idHash },
          })
          .set(AnalyticsHttpContextTokens.FAIL_EVENT_DATA, {
            name: 'public_ui_password_fail',
            description: 'Password is wrong',
            properties: { id: idHash },
          }),
      }
    );
  }
}
