import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { HostingVideoChapter, WidgetName } from "../../hosting-interfaces";


@Component({
    selector: 'or-chapters-widget-layout',
    templateUrl: './chapters-widget-layout.component.html',
    styleUrls: ['./chapters-widget-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChaptersWidgetLayoutComponent {
    @Input() isEnableConfigureMode: boolean = false;
    @Input() chapters: HostingVideoChapter[] = [];
    @Input() forceDisplayHours: boolean = false;
    @Output() clickChapter = new EventEmitter<HostingVideoChapter>();

    WidgetName = WidgetName;
}