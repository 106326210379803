import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize',
  standalone: true,
})
export class PluralizePipe implements PipeTransform {
  transform(count: number, singularText: string, pluralText: string): string {
    const text = count === 1 ? singularText : pluralText;
    return (!count || count < 0 ? 0 : count) + ` ${text}`;
  }
}
